import React from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DownloadIcon from '@mui/icons-material/Download';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from "@mui/material/Modal";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import { config } from './config';
import './Home.css';


function downloadFile(filePath) {
  const url = `${config["url"]}/download?file_path=${encodeURIComponent(filePath)}`;

  fetch(url)
      .then(response => {
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
          return response.blob();
      })
      .then(blob => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filePath.split('/').pop());
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
      })
      .catch(error => console.error('There was an error downloading the file:', error));
}


const CustomAlert = ({ message, onOk, onCancel }) => {
  return (
    <div className="custom-alert-overlay">
      <div className="custom-alert">
        <h3>{message}</h3>
        <div>
          <Button variant='contained' style={{
            background: 'green',
            cursor: 'pointer',
            width: '30%',
          }} onClick={onOk}>OK</Button>
          <Button variant='contained' style={{
            background: 'red',
            cursor: 'pointer',
            width: '30%',
          }} onClick={onCancel}>Continue</Button>
        </div>
      </div>
    </div>
  );
};

const Home = () => {
  const [searchInput, setSearchInput] = React.useState('');
  const [searchResults, setSearchResults] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [numDocs, setSelected] = React.useState(null);
  const [selectedStartDate, setSelectedStartDate] = React.useState(null);
  const [selectedEndDate, setSelectedEndDate] = React.useState(null);
  const [showForm, setShowForm] = React.useState(true);
  const [showAlert, setShowAlert] = React.useState(false);
  const [metadata, setMetadata] = React.useState({});
  const [isMetadataModalOpen, setIsMetadataModalOpen] = React.useState(false);
  const [isMetadataLoading, setIsMetadataLoading] = React.useState(false);

  const convertToCSV = (objArray) => {
    if (!objArray || !Array.isArray(objArray) || objArray.length === 0) {
      throw new Error("Input is null, undefined, or not an array with elements");
    }
  
    const keys = Object.keys(objArray[0]);
  
    const csvContent = [
      keys.join(','), // header row
      ...objArray.map(row => {
        return keys.map(key => {
          const value = row[key];
          return typeof value === 'string' ? `"${value.replace(/\n/g, ' ').replace(/"/g, '""')}"` : value;
        }).join(',');
      })
    ].join('\n');
  
    return csvContent;
  };
  
  const createCSVBlob = (csvData) => {
    return new Blob([csvData], { type: 'text/csv' });
  };
  
  const downloadCSV = (csvBlob, filename) => {
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', filename);
    a.click();
  };
  
  const handleDownload = async () => {
    const url = `${config["url"]}/get_csv`;
    try {
      setIsMetadataLoading(true);
      const keysArray = searchResults.map(doc => doc.key);
      // console.log(keysArray);
      const res = await axios.post(url, keysArray);
      if (res.data.success) {
        console.log(res.data.csv_list);
        const data = res.data.csv_list;
        // let fileName = data[0].Patient_ID; // Assuming Patient_ID is consistent in all objects
        // remove .pdf from the file name
        // fileName = fileName.split('.pdf')[0];
        const csvData = convertToCSV(data);
        const csvBlob = createCSVBlob(csvData);
        downloadCSV(csvBlob, `metadata.csv`);
      }
      setIsMetadataLoading(false);
    } catch (error) {
      console.log(error);
      setIsMetadataLoading(false);
    }
  };
  

  const handleOk = () => {
    console.log('User clicked OK');
    setShowAlert(false);
    return;
  };

  const handleCancel = async () => {
    console.log('User clicked Cancel');
    setShowAlert(false);
    try {
      console.log(searchInput, numDocs, selectedStartDate, selectedEndDate);
      setIsLoading(true);
      const res = await axios.post(`${config["url"]}/search`, { searchInput, numDocs, selectedStartDate, selectedEndDate });
      setIsLoading(false);
      console.log(res.data);
      if (res.data.success) {
        console.log(res.data.relevant_docs);
        setIsLoaded(true);
        setShowForm(false);
        setSearchResults(res.data.relevant_docs);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log(searchInput, numDocs, selectedStartDate, selectedEndDate);
      setIsLoading(true);
      const res = await axios.post(`${config["url"]}/search`, { searchInput, numDocs, selectedStartDate, selectedEndDate });
      setIsLoading(false);
      console.log(res.data);
      if (res.data.success) {
        console.log(res.data.relevant_docs);
        setIsLoaded(true);
        setShowForm(false);
        setSearchResults(res.data.relevant_docs);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadFile = (path) => {
    const filePath = String(path);
    downloadFile(filePath);
  };

  const handleGetMetadata = async (file_name) => {
    const url = `${config["url"]}/get_metadata?file_name=${file_name}`;
    try {
      setIsMetadataLoading(true);
      const res = await axios.get(url);
      if (res.data.success) {
        console.log(res.data.download_csv);
        setMetadata(res.data.download_csv);
        setIsMetadataModalOpen(true);
      }
      setIsMetadataLoading(false);
    } catch (error) {
      console.log(error);
      setIsMetadataLoading(false);
    }
  };

  const handleLastDaySCVDownload = async () => {
    const url = `${config["url"]}/download_last_day_csv`;

    fetch(url)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.blob();
        })
        .then(blob => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `last_day_metadata.csv`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        })
        .catch(error => console.error('There was an error downloading the file:', error));
  }

  return (
    <div className='outer'>
      {isLoading ? (
        <div style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <CircularProgress />
        </div>
      ) : (
        <div className='contents'>
          {isLoaded && showForm &&
            <Button
              variant='contained'
              style={{
                position: 'fixed',
                left: 15,
                top: 15,
                background: 'green',
                color: 'white',
              }}
              onClick={() => {
                setShowForm(showForm => !showForm);
              }}
              className='search-button'
            >
              {showForm ? 'Back to Results' : 'Back to Search'}
            </Button>
          }

          {isLoaded && !showForm &&
            <Button
              variant='contained'
              style={{
                position: 'fixed',
                left: 15,
                top: 15,
                background: '#F3F3F2',
                color: 'black',
              }}
              onClick={() => {
                setShowForm(showForm => !showForm);
              }}
              className='search-button'
            >
              {showForm ? 'Back to Results' : 'Back to Search'}
            </Button>
          }

          {showForm &&
            <Button
              variant='contained'
              style={{
                position: 'fixed',
                right: 15,
                top: 15,
                background: '#36BA98',
                color: 'white',
              }}
              onClick={() => {
                handleLastDaySCVDownload();
              }}
              className='search-button'
            >
              <DownloadIcon style={{marginRight:'10px'}} /> Last Day CSV
            </Button>
          }

          {showForm &&
            <div className='form-data'>
              <h1 className='Title'>MedSearch</h1>
              <form className='myForm' onSubmit={handleSubmit}>
                <div className='input-container'>
                  <label>Enter a query:</label>
                  <input type='text' className='input' value={searchInput} onChange={(e) => setSearchInput(e.target.value)} placeholder='Default: CT and MRI scans containing diagnosis for suspected cases or confirmed cases of malignancy or neoplasm or cancer or metastasis' />
                </div>
                <div className='input-container'>
                  <label>Number of documents:</label>
                  <input type='text' className='input' value={numDocs} onChange={(e) => setSelected(e.target.value)} placeholder='Default: All' />
                </div>
                <div className='input-container'>
                  <label>From</label>
                  <DatePicker
                    className='date-el'
                    selected={selectedStartDate}
                    onChange={(date) => setSelectedStartDate(date)}
                    dateFormat="yyyy/MM/dd"
                    placeholderText='Enter Start Date'
                  />
                </div>
                <div className='input-container'>
                  <label>To</label>
                  <DatePicker
                    className='date-el'
                    selected={selectedEndDate}
                    onChange={(date) => setSelectedEndDate(date)}
                    dateFormat="yyyy/MM/dd"
                    placeholderText='Enter End Date'
                  />
                </div>
                <Button variant='contained' type='submit' className='search-button'>Search</Button>
              </form>
              {showAlert && (
                <CustomAlert
                  message="Please enter at least 500 words for precise results"
                  onOk={handleOk}
                  onCancel={handleCancel}
                />
              )}
            </div>
          }

          {isLoaded &&
            <div style={{
              width: '100%',
              height: '100%',
              display: showForm ? 'none' : 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-around',
              cursor: isMetadataLoading ? 'wait' : 'default',
            }}>

              <h1 style={{
                margin: 0,
                padding: 0,
              }}>Search Results</h1>
              <div className='result-header'>
                <Button variant='contained'
                  style={{
                    background: '#28a745',
                    color: 'white',
                    width: '100%',
                    height: '50%',
                    paddingLeft:0,
                    paddingRight:0,
                    cursor: isMetadataLoading ? 'wait' : 'pointer',
                  }}
                  onClick={() => handleDownload()}
                >
                  <DownloadIcon style={{marginRight:'5px'}}/>
                   Download CSV  
                </Button>
              </div>
              <div className='result-data'>
                {searchResults && searchResults.map((item, index) => (
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    width: '100%',
                    background: index % 2 === 0 ? '#FDFAFE' : 'white',
                  }} key={index}>
                    
                    <div className='result-el-div' style={{ width: '5%' }}>
                      {index + 1}. &nbsp;
                    </div>
                    <div className='result-el-div' style={{ width: '50%' }}>
                      <p
                        style={{
                          cursor: isMetadataLoading ? 'wait' : 'pointer',
                          color: 'blue',
                          textDecoration: 'underline',
                        }}
                        onClick={() => handleDownloadFile(item.value)}>{item.key}
                      </p>
                    </div>
                    <div className='result-el-div' 
                      style={{ 
                        width: '15%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}>
                      <Button variant='contained'
                        style={{
                          background: '#5019e6',
                          color: 'white',
                          width: '100%',
                          height: '50%',
                          paddingLeft:0,
                          paddingRight:0,
                          cursor: isMetadataLoading ? 'wait' : 'pointer',
                        }}
                        onClick={() => handleGetMetadata(item.key)}
                      >View Metadata
                      </Button>
                      {/* <Button variant='contained'
                        style={{
                          background: '#28a745',
                          color: 'white',
                          width: '30%',
                          height: '50%',
                          paddingLeft:0,
                          paddingRight:0,
                          cursor: isMetadataLoading ? 'wait' : 'pointer',
                        }}
                        onClick={() => handleDownload(item.key)}
                      >
                        <DownloadIcon  />
                         CSV  
                      </Button> */}
                    </div>
                  </div>
                ))}
              </div>

              <Modal
                open={isMetadataModalOpen}
                onClose={() => setIsMetadataModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div className='metadata'>
                  <TableContainer>
                    <Table>
                      {/* <TableHead>
                        <TableRow style={{background:'red'}}>
                          <TableCell>Column Name</TableCell>
                          <TableCell>Value</TableCell>
                        </TableRow>
                      </TableHead> */}
                      <TableBody>
                        {Object.keys(metadata).map((key, index) => (
                          <TableRow key={index}>
                            <TableCell>{key}</TableCell>
                            <TableCell>{metadata[key]}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Modal>
            </div>
          }
        </div>
      )}
    </div>
  );
};

export default Home;
